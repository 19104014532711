<template>
  <div class="page-leilao-container">
    <email-cobranca-view @update="load" ref="cobrancaView"></email-cobranca-view>
    <e-row mr>
      <e-col>
        <h5>
          Cobrança de lotes
        </h5>
        <div class="m-b">Você visualizará nesta lista somente os lotes passíveis de cobrança, para visualizar todos os
          lotes
          acesse a seção principal do leilão
        </div>
      </e-col>
      <e-col style="text-align: right">
      </e-col>
    </e-row>

    <div class="box1 m-b-lg">
      <div class="flex">
        <div>
          <div class="">
            <u-btn @click="registrarFinanceiro" color="blue" icon="money-check" icon-type="fa" label="Lançar Vendas no Financeiro" no-caps
                   class="b-radius-3px"/>
          </div>
          <div class="m-t-xs text-center"><a @click="showLog('financeiro')" class="text-blue-grey-7">Ver log de registro no financeiro</a></div>
        </div>
        <div>
          <div class="m-l">
            <u-btn @click="enviarTudo" color="green" icon="envelope" icon-type="fa" label="Enviar todas as cobranças e avisos em massa" no-caps
                   class="b-radius-3px"/>
          </div>
          <div class="m-t-xs text-center"><a @click="showLog('cobrancas')" class="text-blue-grey-7">Ver log de cobranças</a></div>
        </div>
      </div>
    </div>

    <div class="filters row font-11 justify-between">
      <div class="col text-grey-7" style="max-width: 70px; line-height: 20px">
        <!--{{table.serverPagination.rowsNumber}} lote{{table.serverPagination.rowsNumber > 1 ? 's' : ''}}-->
        {{ filteredServerData.length }} lote{{ filteredServerData.length > 1 ? 's' : '' }}
        <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/>
      </div>
      <div class="col m-l-lg" style="line-height: 20px; max-width: 500px">
        <div class="row">
          <div class="col flex flex-center items-center">
            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.semCobranca">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Sem cobrança</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.comCobranca">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Cobrança enviada</span>
              </label>
            </erp-checkbox>

          </div>
        </div>
      </div>
      <div class="col" style="max-width: 140px">
        <erp-s-field
            class="font-10"
            view="lr"
            label="Buscar:"
        >
          <helper-input-btn>
            <erp-input simple-border v-model="table.filters.pesquisa"/>
            <a class="m-l-xs text-black">IR</a>
          </helper-input-btn>
        </erp-s-field>
      </div>
      <div class="col m-l m-b-n" style="max-width: 110px; text-align: right">
        <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>
      </div>
    </div>
    <div class="erp-list no-pad">
      <u-table
          ref="table"
          color="primary"
          :data="filteredServerData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr :class="{disabled: !props.row.arremate}" slot="body" slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="loteString" :props="props">
            <div>{{ props.row.numeroString }}</div>
          </u-td>
          <u-td key="lote" :props="props">
            <div>{{ props.row.numero }}</div>
          </u-td>
          <u-td key="comitente" :props="props" class="uppercase td-limit l-130"
                :title="props.row.bem.comitente && props.row.bem.comitente.pessoa.name">
            {{ props.row.bem.comitente && props.row.bem.comitente.pessoa.name }}
          </u-td>
          <u-td :title="props.row.descricao" class="td-limit l-130 uppercase" key="descricao"
                :props="props">
            {{ props.row.descricao }}
          </u-td>
          <u-td key="valorMinimo" :props="props">
            R$ {{ props.row.valorMinimo|moeda }}
          </u-td>
          <u-td key="arremate" :props="props">
            <span v-if="props.row.arremate">{{ props.row.arremate.arrematante.apelido }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="lances" :props="props">
            R$ {{ props.row.valorArremate|moeda }}
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-lote :lote="props.row"/> <!-- TODO: PENDENTE DE PAGAMENTO / PAGO -->
          </u-td>
          <u-td class="text-uppercase" key="cobranca" :props="props">
            <div class="status-envio btns-acoes-envio-cob">
              <!--              <u-icon name="envelope" type="fa" class="m-r-xs" /> Não enviado-->
              <u-btn
                  v-if="props.row.arremate"
                  @click="$refs.cobrancaView.load(props.row.id)"
                  size="xs" icon="envelope"
                  :class="{'text-danger': !(props.row.arremate.cobrancas && props.row.arremate.cobrancas.length)}"
                  :label="props.row.arremate.cobrancas && props.row.arremate.cobrancas.length ? props.row.arremate.cobrancas.length : '0'"
                  dense
                  icon-type="fa" icon-style="light"
              />
              <u-btn
                  v-if="props.row.arremate"
                  dense
                  icon="file" icon-type="fa" icon-style="light" class="m-l-sm"
                  @click="downloadFatura(props.row)"
                  />
              <u-icon
                  v-if="props.row.arremate && props.row.arremate.cobrancas && props.row.arremate.cobrancas.find(c => c.message && c.message.dateReaded)"
                  name="eye" type="fa" icon-style="light" class="m-l-sm text-green"
                  :title="props.row.arremate.cobrancas.find(c => c.message && c.message.dateReaded).message.dateReaded|formatDate"/>
            </div>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <default-list-menu
                    @abrir="abrir(props.row)"
                    @abrirBem="abrirBem(props.row.bem.id)"
                    @removerLote="remover(props.row)"
                    disable-remover-lote
                    :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
    </div>
    <log-envio-cobranca :type="log.type" :action="log.action" :id="log.id" :leilao="leilao" v-if="showLogOperacional" @close="showLogOperacional = false" />
  </div>
</template>

<script>
import {UCheckbox, UTable, UTd, UTh, UTr, LocalStorage} from 'uloc-vue'
import {EBtnTableOptions, ErpCheckbox, ErpSField, ErpInput, HelperInputBtn} from 'uloc-vue-plugin-erp'
import {listLotesCobranca as list} from '../../../../domain/leiloes/services'
import MenuOptions from '../../../layout/context-menu/context-window-options'
import DefaultListMenu from '../context/list-menu-lote'
import StatusLote from '../include/StatusLote'
import loteWindow from '../../windows/lote'
import bemWindow from '../../../bem/windows/bem'
import AlteracaoStatusLoteMixin from '../include/AlteracaoStatusLoteMixin'
import listOptions from '../../windows/listOptions'
import DefaultMixin from '@/components/leiloes/mixin'
import EmailCobrancaView from '@/components/leiloes/components/cobranca/EmailCobrancaView'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import LogEnvioCobranca from "components/leiloes/components/cobranca/include/LogEnvioCobranca"
import {
  enviarCobrancaEmMassa,
  faturaArrematacao,
  registrarVendasFinanceiro
} from "@/domain/leiloes/services/financeiro";
import {donwloadFile} from "@/utils/downloadFile";

const listName = 'leilao.cobranca.list'
const listStorage = tableColumnsCache(listName, [
  {label: 'LT', name: 'loteString', active: true, sortable: true, ordering: 1},
  {label: 'LTN', name: 'lote', active: true, sortable: true, ordering: 1},
  {label: 'Comitente', name: 'comitente', active: true, sortable: true, ordering: 1},
  {label: 'Descrição', name: 'descricao', active: true, sortable: true, ordering: 1},
  {label: 'Mínimo', name: 'valorMinimo', active: true, sortable: true, ordering: 1},
  {label: 'Arremate', name: 'arremate', active: true, sortable: true, ordering: 1},
  {label: 'Lance', name: 'lances', active: true, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1},
  {label: 'Cobrança', name: 'cobranca', active: true, sortable: true, ordering: 1}
], 0);

let filters = {
  semCobranca: true,
  comCobranca: true,
  pesquisa: null
}

export default {
  name: 'ListaLotes',
  mixins: [AlteracaoStatusLoteMixin, DefaultMixin],
  inject: ['page', 'erplayout'],
  props: {},
  data() {
    return {
      leilao: this.page.leilao,
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 500
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            required: o.required || null,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      showLogOperacional: false,
      log: {
        type: 'cobranca',
        action: 'cobrancaLeilao',
        entity: 'leilao',
        id: this.page.leilao.id
      }
    }
  },
  watch: {
    'table.filters.comCobranca'(v) {
      this.$nextTick(() => {
        this.load()
      })
    },
    'table.filters.semCobranca'(v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  components: {
    LogEnvioCobranca,
    EmailCobrancaView,
    StatusLote,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    DefaultListMenu,
    ErpCheckbox,
    ErpSField,
    ErpInput,
    HelperInputBtn,
    ERow,
    ECol
  },
  mounted() {
    this.page.setActive('cbl')
    console.log(this.leilao)
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => {
        /*if (item.name === 'imagem') {
          return !this.table.filters.ocultarFotos
        }*/
        return item.active
      }).map(item => item.name)
    },
    filteredServerData() {
      let data = this.table.serverData.slice()

      let busca = this.table.filters.pesquisa
      if (busca && String(busca).length > 0) {
        console.log(busca)
        data = data.filter(lt => {
          return String(lt.descricao).toLowerCase().indexOf(busca) > -1 ||
              String(lt.bem.comitente.pessoa.name).toLowerCase().indexOf(busca) > -1
              || String(lt.numero).toLowerCase().indexOf(busca) > -1
              || lt.arremate && String(lt.arremate.arrematante.apelido).toLowerCase().indexOf(busca) > -1
              || lt.arremate && String(lt.arremate.arrematante.pessoa.name).toLowerCase().indexOf(busca) > -1
          // TODO: Implementar outros campos, principalmente arremate
        })
      }
      return data
      /*return this.table.serverData.slice().sort(function (a, b) {
        a = Number(a['numero'])
        b = Number(b['numero'])
        return (a === b ? 0 : a > b ? 1 : -1) * 1 // -1 = desc, 1 = asc
      })*/
    }
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {
      this.table.loading = true
      this.table.selected = []
      const extraFilters = []
      if (!(this.table.filters.comCobranca && this.table.filters.semCobranca)) {
        extraFilters.push('filtrarPorCobranca=' + (this.table.filters.comCobranca ? 'Com' : 'Sem'))
      }
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&${extraFilters.join('&')}`)

      list(this.leilao.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result || []

            this.table.loading = false
            this.loadedData()
          })
          .catch(error => {
            this.loading = false
          })
    },
    abrir(lote) {
      this.loteWindow(lote)
    },
    abrirBem(id) {
      this.bemWindow(id)
    },
    loteWindow: loteWindow,
    bemWindow: bemWindow,
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    loadedData() {
      this.$emit('loaded', this.table.serverData)
    },
    windowLoteEvents(wid) {
      this.$uloc.window.listen(wid, {
        update: (wid, lote) => {
          console.log('Updated lote event')
          // this.load()
          this.load()
        }
      })
    },
    enviarTudo() {
      this.$uloc.dialog({
        title: 'Você está prestes a executar uma ação irreversível',
        message: 'É altamente recomendável que abra uma ou mais cobranças manuais para pré-visualizar se os dados estão corretos antes de executar o envio em massa.',
        cancel: 'Cancelar',
        ok: 'Já revisei e confirmo o envio',
        color: 'negative'
      }).then(() => {
        this.showLogOperacional = false
        this.$nextTick(() => {
          this.showLog('cobrancas')
          enviarCobrancaEmMassa(this.leilao.id)
              .then((response) => {})
              .catch(error => {
                this.alertApiError(error)
              })
        })
      }).catch(() => {
      })
    },
    registrarFinanceiro() {
      this.$uloc.dialog({
        title: 'Você está prestes a registrar todas as vendas do leilão em Contas a Receber',
        message: 'Tem certeza desta operação?',
        cancel: 'Cancelar',
        ok: 'Sim, prosseguir',
        color: 'negative'
      }).then(() => {
        this.showLogOperacional = false
        this.$nextTick(() => {
          this.showLog('financeiro')
          registrarVendasFinanceiro(this.leilao.id)
              .then((response) => {})
              .catch(error => {
                this.alertApiError(error)
              })
        })
      }).catch(() => {
      })
    },
    downloadFatura (lote) {
      faturaArrematacao(lote.leilao.id, lote.arremate.arrematante.id, true, lote.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    showLog (tipo) {
      if (tipo === 'cobrancas') {
        this.log.type = 'cobranca'
        this.log.action = 'cobrancaLeilao'
        this.log.entity = 'leilao'
        this.log.id = this.leilao.id
      }
      if (tipo === 'financeiro') {
        this.log.type = 'financeiroLote'
        this.log.action = 'financeiroLote'
        this.log.entity = 'leilao'
        this.log.id = this.leilao.id
      }
      this.showLogOperacional = true
    }
  }
}
</script>

<style lang="stylus">
.btns-acoes-envio-cob {
  font-size 14px

  .u-focus-helper {
    display none !important
  }
}

</style>
